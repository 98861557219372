<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="服务对象名称" prop="serveObjectName">
        <el-input v-model="dataForm.serveObjectName" placeholder="服务对象名称"></el-input>
      </el-form-item>
      <el-form-item label="服务类型" prop="sid">
        <el-input v-model="dataForm.sid" placeholder="服务类型"></el-input>
      </el-form-item>
      <el-form-item label="服务对象类型" prop="oid">
        <el-input v-model="dataForm.oid" placeholder="服务对象类型"></el-input>
      </el-form-item>
      <el-form-item label="服务人次" prop="serveNum">
        <el-input v-model="dataForm.serveNum" placeholder="服务人次"></el-input>
      </el-form-item>
      <el-form-item label="照片" prop="imgs">
        <el-input v-model="dataForm.imgs" placeholder="服务的照片地址，以; 分隔开"></el-input>
      </el-form-item>
      <el-form-item label="服务人地址" prop="address">
        <el-input v-model="dataForm.address" placeholder="服务人地址，国家，省份，市区城市，所在区县，以分号分隔开"></el-input>
      </el-form-item>
      <el-form-item label="服务日期，格式化的" prop="serveDate">
        <el-input v-model="dataForm.serveDate" placeholder="服务日期，格式化的"></el-input>
      </el-form-item>
      <el-form-item label="用户的唯一标识符" prop="uid">
        <el-input v-model="dataForm.uid" placeholder="用户的唯一标识符"></el-input>
      </el-form-item>
      <el-form-item label="小程序版本" prop="miniProgVersion">
        <el-input v-model="dataForm.miniProgVersion" disabled placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="信息创建日期" prop="createDete">
        <el-input v-model="dataForm.createDete" disabled placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddOrUpdateMixin from "@/mixins/AddOrUpdate";

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        serveObjectName: [
          {required: true, message: '服务对象名称不能为空', trigger: 'blur'}
        ],
        sid: [
          {required: true, message: '服务类型不能为空', trigger: 'blur'}
        ],
        oid: [
          {required: true, message: '服务对象类型不能为空', trigger: 'blur'}
        ],
        serveNum: [
          {required: true, message: '服务人次不能为空', trigger: 'blur'}
        ],
        imgs: [
          {required: true, message: '服务的照片地址，以; 分隔开不能为空', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '服务人地址，国家，省份，市区城市，所在区县，以分号分隔开不能为空', trigger: 'blur'}
        ],
        serveDate: [
          {required: true, message: '服务日期，格式化的不能为空', trigger: 'blur'}
        ],
        uid: [
          {required: true, message: '用户的唯一标识符不能为空', trigger: 'blur'}
        ],
        createDete: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.doInit(id, `/wechat/servecontent/info`, 'serveContent').then((resp)=> {
        this.dataForm.createDete = this.$utils.dateFormat('yyyy-MM-dd hh:mm', resp.createDete)
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/servecontent/${!this.dataForm.id ? 'save' : 'update'}`, {
        'id': this.dataForm.id || undefined,
        'serveObjectName': this.dataForm.serveObjectName,
        'sid': this.dataForm.sid,
        'oid': this.dataForm.oid,
        'serveNum': this.dataForm.serveNum,
        'imgs': this.dataForm.imgs,
        'address': this.dataForm.address,
        'serveDate': this.dataForm.serveDate,
        'uid': this.dataForm.uid,
      })
    }
  }
}
</script>
